<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                        <b-col sm="6">
                            <ValidationProvider name="Select Category" vid="category_id" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Category <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="categoryList"
                                    id="category_id"
                                    rows="6"
                                    v-model="formData.category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Article Open Type" vid="open_type" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Article Open Type <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="openTypeList"
                                    id="open_type"
                                    rows="6"
                                    v-model="formData.open_type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Ads Network" vid="open_type" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Ads Network <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="adsNetWorkList"
                                    id="ads_network_id"
                                    rows="6"
                                    v-model="formData.ads_network_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Title" vid="title" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Title <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="title"
                                    rows="6"
                                    v-model="formData.title"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">

                            <ValidationProvider name="Point" vid="point" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Point <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="point"
                                    rows="6"
                                    v-model="formData.point"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">

                            <ValidationProvider name="Articel Url" vid="article_link" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Articel Url <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="article_link"
                                    rows="6"
                                    v-model="formData.article_link"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">

                            <ValidationProvider name="Time (sec)" vid="time_sec" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Time (sec) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="time_sec"
                                    rows="6"
                                    v-model="formData.time_sec"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">

                            <ValidationProvider name="Thumbnail" vid="thumbnail_url" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Thumbnail Image<span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="thumbnail_url"
                                    rows="6"
                                    v-model="formData.thumbnail_url"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        
                        <b-col sm="6">

                            <ValidationProvider name="Expire Date" vid="expire_date" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Expire Date
                                </template>
                                <b-form-input
                                    id="expire_date"
                                    type="date"
                                    rows="6"
                                    v-model="formData.expire_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    

                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                        </div>
                    </div>
                </b-form>
            </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        title: '',
        category_id: '',
        ads_network_id: null,
        required_point: '',
        thumbnail: null,
        description: null,
        min_point: null,
        max_point: null,
        description: null,
      }
    }
  },
  computed: {
      playInList () {
        return this.$store.state.commonObj.playInList
      },
      categoryList () {
        return this.$store.state.commonObj.articleCategoryList
      },
      adsNetWorkList () {
        return this.$store.state.commonObj.adsNetWorkList
      },
      loading () {
        return this.$store.state.static.loading
      },
      openTypeList () {
        return [
            {
                value: 1,
                text: 'In app'
            },
            {
                value: 2,
                text: 'Browser'
            },
            {
                value: 3,
                text: 'Custom'
            }
        ]
      }
  },
  methods: {
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(item))
    },
    async register () {
          this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
        let result = null
        if (this.id) {
            result = await RestApi.putData(baseUrl, `${'api/articles/update'}/${this.id}`, this.formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/articles/store', this.formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            if (!this.id) {
                this.formData.content = ''
            }
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
