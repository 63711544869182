var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Select Category","vid":"category_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":_vm.categoryList,"id":"category_id","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Article Open Type","vid":"open_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Article Open Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":_vm.openTypeList,"id":"open_type","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.open_type),callback:function ($$v) {_vm.$set(_vm.formData, "open_type", $$v)},expression:"formData.open_type"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ads Network","vid":"open_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ads Network "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":_vm.adsNetWorkList,"id":"ads_network_id","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.ads_network_id),callback:function ($$v) {_vm.$set(_vm.formData, "ads_network_id", $$v)},expression:"formData.ads_network_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"title","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Point","vid":"point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Point "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"point","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.point),callback:function ($$v) {_vm.$set(_vm.formData, "point", $$v)},expression:"formData.point"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Articel Url","vid":"article_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Articel Url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"article_link","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.article_link),callback:function ($$v) {_vm.$set(_vm.formData, "article_link", $$v)},expression:"formData.article_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Time (sec)","vid":"time_sec","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Time (sec) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"time_sec","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.time_sec),callback:function ($$v) {_vm.$set(_vm.formData, "time_sec", $$v)},expression:"formData.time_sec"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Thumbnail","vid":"thumbnail_url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thumbnail Image"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"thumbnail_url","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.thumbnail_url),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail_url", $$v)},expression:"formData.thumbnail_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Expire Date","vid":"expire_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Expire Date ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"expire_date","type":"date","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.expire_date),callback:function ($$v) {_vm.$set(_vm.formData, "expire_date", $$v)},expression:"formData.expire_date"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))]),_vm._v("   "),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-1')}}},[_vm._v("Close")])],1)])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }