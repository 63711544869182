export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'sidebar.dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            items: [],
            permission: 'dashboard'
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Users',
            route: '/users',
            icon: 'cil-user',
            permission: 'user-manage',
            items: [{
                    name: ' - All Users',
                    to: '/users/all',
                    permission: 'all-users'

                },
                {
                    name: ' - Active Users',
                    to: '/users/active',
                    permission: 'active-users'

                },
                {
                    name: ' - Block Users',
                    to: '/users/block',
                    permission: 'block-users'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Task History',
            route: '/task-history',
            icon: 'cil-dollar',
            permission: 'withdraw-manage',
            items: [
                {
                    name: ' - Completed',
                    to: '/task-history/approve',
                    permission: 'withdraw-completed'
                },
                {
                    name: ' - Rejected',
                    to: '/task-history/reject',
                    permission: 'withdraw-rejected'
                },
                {
                    name: ' - All List',
                    to: '/task-history/all',
                    permission: 'all-withdraw'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Withdraw',
            route: '/withdraws',
            icon: 'cil-dollar',
            permission: 'withdraw-manage',
            items: [
                {
                    name: ' - Withdraw Category',
                    to: '/withdraws/withdraw-category',
                    permission: 'withdraw-category'
                },
                {
                    name: ' - Withdraw Method',
                    to: '/withdraws/withdraw-method',
                    permission: 'withdraw-method'
                },
                {
                    name: ' - Pending',
                    to: '/withdraws/pending',
                    permission: 'withdraw-pending'
                },
                {
                    name: ' - Completed',
                    to: '/withdraws/approve',
                    permission: 'withdraw-completed'
                },
                {
                    name: ' - Rejected',
                    to: '/withdraws/reject',
                    permission: 'withdraw-rejected'
                },
                {
                    name: ' - All List',
                    to: '/withdraws/all',
                    permission: 'all-withdraw'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Article',
            route: '/withdraws',
            icon: 'cil-list',
            permission: 'article-manage',
            items: [
                {
                    name: ' - Category',
                    to: '/articles/categories',
                    permission: 'article-category'
                },
                {
                    name: ' - Articles',
                    to: '/articles',
                    permission: 'articles'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Quiz',
            route: '/withdraws',
            icon: 'cil-options',
            permission: 'quiz-manage',
            items: [
                {
                    name: ' - Category',
                    to: '/quizs/categories',
                    permission: 'quiz-category'

                },
                {
                    name: ' - Quizs',
                    to: '/quizs',
                    permission: 'quiz'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'General Setting',
            route: '/general-setting',
            icon: 'cil-settings',
            permission: 'general-setting',
            items: [
                {
                    name: ' - Send Notification',
                    to: '/general-setting/send-notification',
                    permission: 'dashboard',
                },
                {
                    name: ' - VPN List',
                    to: '/general-setting/vpn-list',
                    permission: 'dashboard',
                },
                {
                    name: ' - Ads Networks',
                    to: '/general-setting/ads-networks',
                    permission: 'dashboard',
                },
                {
                    name: ' - Setting',
                    to: '/general-setting/setting',
                    permission: 'dashboard',
                },
                {
                   name: ' - App Update',
                   to: '/general-setting/app-update',
                   permission: 'dashboard',
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'User Management',
            route: '/app-users',
            icon: 'cil-user',
            permission: 'app-user-manage',
            items: [
                {
                    _name: 'CSidebarNavItem',
                    name: ' - Admin List',
                    to: '/app-management/admin-users',
                    icon: 'cil-people',
                    items: [],
                    permission: 'admin-user-list'
                  },
                  {
                    _name: 'CSidebarNavItem',
                    name: ' - Roles',
                    to: '/app-management/roles',
                    icon: 'cil-list',
                    items: [],
                    permission: 'role-list'
                  },
                  {
                    _name: 'CSidebarNavItem',
                    name: ' - Permissions',
                    to: '/app-management/permissions',
                    icon: 'cil-list',
                    items: [],
                    permission: 'permission-list'
                  }
            ]
        }
    ]
}]