import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')

const allWithdraw = () =>
    import ('@/views/withdraw/List')
const pendinWithdraw = () =>
    import ('@/views/withdraw/Pending')
const approveWithdraw = () =>
    import ('@/views/withdraw/Approve')
const rejectWithdraw = () =>
    import ('@/views/withdraw/Reject')


const allHistory = () =>
    import ('@/views/taskHistory/List')
const approveHistory = () =>
    import ('@/views/taskHistory/Approve')
const rejectHistory = () =>
    import ('@/views/taskHistory/Reject')


const allUser = () =>
    import ('@/views/user-signup/List')
const activeUser = () =>
    import ('@/views/user-signup/ActiveList')
const blockUser = () =>
    import ('@/views/user-signup/BlockList')
const profile = () =>
    import ('@/views/user-signup/Form')

const setting = () =>
    import ('@/views/setting/Form')
const update = () =>
    import ('@/views/update/Form')
const changePass = () =>
    import ('@/views/change-pass/Form')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const Register = () =>
    import ('@/views/pages/Register')

Vue.use(Router)

const routes = [{
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        component: TheContainer,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'withdraws',
                redirect: '/base/cards',
                name: 'Withdraw',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'withdraw-category',
                        name: 'Popovers',
                        component: () => import ('@/views/withdraw/withdraw-category/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'withdraw-method',
                        name: 'Popovers',
                        component: () => import ('@/views/withdraw/withdraw-method/List'),
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'all',
                        name: 'Popovers',
                        component: allWithdraw,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'approve',
                        name: 'Popovers',
                        component: approveWithdraw,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'pending',
                        name: 'Popovers',
                        component: pendinWithdraw,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'reject',
                        name: 'Popovers',
                        component: rejectWithdraw,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'task-history',
                redirect: '/base/cards',
                name: 'Withdraw',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'all',
                        name: 'Popovers',
                        component: allHistory,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'approve',
                        name: 'Popovers',
                        component: approveHistory,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'reject',
                        name: 'Popovers',
                        component: rejectHistory,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'users',
                redirect: '/base/cards',
                name: 'Users',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'all',
                        name: 'Popovers',
                        component: allUser,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'active',
                        name: 'Popovers',
                        component: activeUser,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'block',
                        name: 'Popovers',
                        component: blockUser,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'profile',
                        name: 'Popovers',
                        component: profile,
                        meta: {
                            requiresAuth: true,
                        }
                    }
                ]
            },
            {
                path: 'articles',
                redirect: '/base/cards',
                name: 'Users',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'categories',
                        name: 'Popovers',
                        component: () => import ('@/views/articles/category/List'),
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: '/',
                        name: 'Popovers',
                        component: () => import ('@/views/articles/List'),
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    
                ]
            },
            {
                path: 'quizs',
                redirect: '/base/cards',
                name: 'Users',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'categories',
                        name: 'Popovers',
                        component: () => import ('@/views/quizs/category/List'),
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: '/',
                        name: 'Popovers',
                        component: () => import ('@/views/quizs/List'),
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    
                ]
            },
            {
                path: 'general-setting',
                redirect: '/base/cards',
                name: 'General Setting',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'send-notification',
                        name: 'Popovers',
                        component: () => import ('@/views/notification/Form'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'vpn-list',
                        name: 'Popovers',
                        component: () => import ('@/views/vpn/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'ads-networks',
                        name: 'Popovers',
                        component: () => import ('@/views/ads-networks/List'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'setting',
                        name: 'Popovers',
                        component: setting,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'app-update',
                        name: 'Popovers',
                        component: update,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'change-password',
                        name: 'changePassword',
                        component: changePass,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'app-management',
                name: 'appUsers',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [  {
                    path: 'admin-users',
                    name: 'user-list',
                    component: () => import('../views/user/List'),
                    meta: {
                      requiresAuth: true,
                      name: 'user-list'
                    }
                  },
                  {
                    path: 'roles',
                    name: 'role-list',
                    component: () => import('../views/role/List'),
                    meta: {
                      requiresAuth: true,
                      name: 'role-list'
                    }
                  },
                  {
                    path: 'permissions',
                    name: 'permission-list',
                    component: () => import('../views/permission/List'),
                    meta: {
                      requiresAuth: true,
                      name: 'permission-list'
                    }
                  }
                ]
            }
        ]
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '404',
                name: 'Page404',
                component: Page404,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
]


const router = new Router({
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/pages/login')
    } else {
        next()
    }
})

export default router